import {connect} from 'react-redux'
import VersionMain from "./VersionMain";
import * as versionActions from '../actions/version'

const mapStateToProps = (state) => {
  return {
    version: state.version
  };
};

const mapDispatchToProps  = (dispatch) => {
  return {
    loadFrontendVersion: () => dispatch(versionActions.loadFrontendVersion()),
    loadBackendVersion: () => dispatch(versionActions.loadBackendVersion())
  }
};

const VersionConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
) (VersionMain);

export default VersionConnector;
