import React, {Component} from 'react'
import Kontaktperson from "./Kontaktperson";

class KontaktpersonerView extends Component {

  render() {

    const {kontaktpersoner, erSupport} = this.props;

    let idx=0;

    if (kontaktpersoner === null || kontaktpersoner === undefined || kontaktpersoner.length === 0) {
      return null;
    }

    return (
        <div>
          <h3>Kontaktpersoner</h3>
            {
              kontaktpersoner.map(kontaktperson => {
                return <Kontaktperson key={kontaktperson.aktoerUuid} kontaktperson={kontaktperson} index={idx++} erSupport={erSupport} />
              })
            }
        </div>
    );
  }
}

export default KontaktpersonerView;