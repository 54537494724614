import React, {Component} from "react";
import AktoerView from "../pages/AktoerView";
import GrupperView from "../pages/GrupperView";
import CloseButton from "../util/CloseButton";
import BeskyttetDataComponent from "../pages/BeskyttetDataComponent";
import FeedbackBox from "../util/FeedbackBox";

class EksternModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            erBrugeradministrator: false,
            feedbackBesked: null
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ekstern !== this.props.ekstern && this.props.visModal) {
            this.setState({erBrugeradministrator: this.props.ekstern.erBrugeradministrator})
            this.setState({feedbackBesked: null})
        }
        if (prevProps.detaljesideAlerts !== this.props.detaljesideAlerts && this.props.visModal && this.props.detaljesideAlerts.fejl.length > 0) {
            if (this.props.detaljesideAlerts.fejl.length > 0) {
                this.setState({feedbackBesked: this.props.detaljesideAlerts.fejl})
            }
        }
        if (prevProps.aktoerErBrugeradm !== this.props.aktoerErBrugeradm && this.props.aktoerErBrugeradm != null && this.props.detaljesideSuccess.success.length > 0) {
            this.setState({erBrugeradministrator: this.props.aktoerErBrugeradm.aktoerErBrugeradm})
            this.setState({feedbackBesked: this.props.detaljesideSuccess.success})
        }
    }

    opdaterBrugeradm(e, ekstern) {
        const {opretBrugeradministrator, sletBrugeradministrator} = this.props;
        e.preventDefault();

        this.setState({feedbackBesked: null})

        if (this.state.erBrugeradministrator === true) {
            sletBrugeradministrator(ekstern.aktoer_uuid)
        } else {
            opretBrugeradministrator(ekstern.aktoer_uuid)
        }
    }

    render() {
        const {visModal, lukModal, ekstern, erSupport} = this.props;
        let eksternNavn;

        if (!visModal)
            return null;

        if (visModal) {
            eksternNavn = ekstern.fornavn + " " + ekstern.efternavn;
        }

        return (
            <div>
                <div className="fds-modal" id="ekstern-modal" aria-hidden={!visModal} role="dialog"
                     aria-modal="true" aria-labelledby="modal-example-title">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="modal-example-title">Ekstern: <BeskyttetDataComponent erSupport={erSupport} data={eksternNavn} erBeskyttet={ekstern.beskyttet}
                                                                                                                  erOverskrift={true}/></h2>
                            <CloseButton lukModal={lukModal}/>
                        </div>
                        <div className="modal-body">
                            <div className="modalcontent">
                                <div className="stil-col">
                                    <AktoerView aktoer={ekstern} erSupport={erSupport}/>
                                    <div className="line">
                                        <div className="label">Rolle</div>
                                        <div className="field">{ekstern.rolle}</div>
                                    </div>
                                  {ekstern.erBrugeradministrator != null &&
                                      <div className="line">
                                        <div className="label">Brugeradministrator</div>
                                        <input type="checkbox" checked={this.state.erBrugeradministrator}
                                               onClick={(e) => this.opdaterBrugeradm(e, ekstern)}/>
                                        {this.state.feedbackBesked != null &&
                                            <FeedbackBox feedbackBesked={this.state.feedbackBesked}
                                                         detaljesideAlerts={this.props.detaljesideAlerts}></FeedbackBox>
                                        }
                                      </div>
                                  }
                                    <GrupperView grupper={ekstern.grupper}/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="buttongroup">
                                <button id="ekstern-close-button" className="margleft10 stil-primary-button button" onClick={lukModal}
                                        title="Lukker dialogen">
                                    Luk
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                {visModal && <div className="modal-backdrop fade show"></div>}
            </div>
        )
    }
}

export default EksternModal

