import constants from '../util/constants.js';

export function versionReducer(state = [], action) {

  switch (action.type) {
    case constants.action.GET_FRONTEND_VERSION_SUCCESS:
      return {...state, frontendVersion: action.version};

    case constants.action.GET_BACKEND_VERSION_SUCCESS:
      return {...state, backendVersion: action.version};

    default:
      return state;
  }
}
