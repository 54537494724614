import {connect} from 'react-redux'
import ForsideMain from "./ForsideMain";
import * as loginActions from "../actions/login";

const mapStateToProps = (state) => {
  return {
    forside: state.forside,
    login: state.login
  };
};

const mapDispatchToProps  = (dispatch) => {
  return {
    loginClear: () => dispatch(loginActions.loginClear()),
    visOrganisationModal: () => dispatch(loginActions.visOrganisationModal()),
    skjulOrganisationModal: () => dispatch(loginActions.skjulOrganisationModal()),
    hentOrganisationer: () => dispatch(loginActions.hentOrganisationer()),
    skiftOrganisation: (organisation) => dispatch(loginActions.skiftOrganisation(organisation))
  }
};

const ForsideConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
) (ForsideMain);

export default ForsideConnector;
