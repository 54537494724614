import React, {Component} from "react";
import AktoerView from "../pages/AktoerView";
import GrupperView from "../pages/GrupperView";
import KontaktpersonerView from "../pages/KontaktpersonerView";
import CloseButton from "../util/CloseButton";
import BeskyttetDataComponent from "../pages/BeskyttetDataComponent";

class ElevModal extends Component {

  render() {
    const {visModal, lukModal, elev, erSupport} = this.props;
    let elevNavn;

    if(!visModal)
      return null;

    if(visModal){
      elevNavn = elev.fornavn + " " + elev.efternavn;
    }

    return (

      <div>
        <div className="fds-modal" id="elev-modal" aria-hidden={!visModal} role="dialog"
             aria-modal="true" aria-labelledby="modal-example-title">
          <div className="modal-content">
            <div className="modal-header">

              <h2 className="modal-title" id="modal-example-title">Elev: <BeskyttetDataComponent erSupport={erSupport} data={elevNavn} erBeskyttet={elev.beskyttet} erOverskrift={true}/></h2>
              <CloseButton lukModal={lukModal}/>
            </div>
            <div className="modal-body">
              <div className="modalcontent">
                <div className="stil-col">
                <AktoerView aktoer={elev} erSupport={erSupport}/>
                <div className="line">
                  <div className="label">Rolle</div>
                  <div className="field">{elev.rolle}</div>
                </div>
                <div className="line">
                  <div className="label">Elevniveau</div>
                  <div className="field">{elev.elevniveau}</div>
                </div>
                <div className="line">
                  <div className="label">Hovedgruppe</div>
                  <div className="field">{elev.hovedgruppe.navn} ({elev.hovedgruppe.gruppekode})</div>
                </div>
                <GrupperView grupper={elev.grupper}/>
                <KontaktpersonerView kontaktpersoner={elev.kontaktpersoner} erSupport={erSupport}/>
              </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="buttongroup">
                <button id="elev-close-button" className="margleft10 stil-primary-button button" onClick={lukModal}
                        title="Lukker dialogen">
                  Luk
                </button>
              </div>
            </div>

          </div>
        </div>

        {visModal && <div className="modal-backdrop fade show"></div>}
      </div>

    )
  }
}

export default ElevModal

