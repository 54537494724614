import fetchJson from '../util/fetch';
import constants from "../util/constants";

export function loginClear() {
  return {
    type: constants.action.LOGIN_CLEAR
  }
}

export function visOrganisationModal() {
  return {
    type: constants.action.VIS_ORGANISATION_MODAL
  }
}

export function skjulOrganisationModal() {
  return {
    type: constants.action.SKJUL_ORGANISATION_MODAL
  }
}

export function hentOrganisationer() {
  return dispatch => {

    dispatch(henterOrganisationer());
    fetchJson(constants.api.hentOrganisationerUrl, 'GET')
        .then((item) => {
          dispatch(hentOrganisationerSuccess(item));
        }).catch(() => {
          dispatch(hentOrganisationerFejl([`Kunne ikke hente organisationer.`]));
        });
  };
}

function henterOrganisationer() {
  return {
    type: constants.action.HENTER_ORGANISATIONER
  }
}

function hentOrganisationerSuccess(organisationer) {
  return {
    type: constants.action.HENT_ORGANISATIONER_SUCCESS,
    organisationer: organisationer
  }
}

function hentOrganisationerFejl(errors) {
  return {
    type: constants.action.HENT_ORGANISATIONER_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function skiftOrganisation(organisation) {
  return dispatch => {
    dispatch(skifterOrganisation());
    fetchJson(constants.api.skiftOrganisationUrl, "POST", organisation)
      .then(bruger => {
        dispatch(skiftOrganisationSuccess(bruger));
      })
      .catch(() => {
        dispatch(skiftOrganisationFejl([`Kunne ikke skifte organisationen. Log ud og prøv igen.`]));
      });
  };
}

function skifterOrganisation() {
  return {
    type: constants.action.SKIFTER_ORGANISATION,
  }
}

function skiftOrganisationSuccess(bruger) {
  window.location.replace(constants.navigation.basename);
  return {
    type: constants.action.SKIFT_ORGANISATION_SUCCESS,
    bruger: bruger
  }
}

function skiftOrganisationFejl(errors) {
  return {
    type: constants.action.SKIFT_ORGANISATION_FEJL,
    alerts: {fejl: errors, info: []}
  }
}

export function supportadgang(instRef) {
  return dispatch => {
    dispatch({ type: constants.action.GIVER_SUPPORTADGANG });
    fetchJson(constants.api.supportadgangUrl, "POST", { instRef: instRef })
        .then(bruger => dispatch({type: constants.action.SUPPORTADGANG_SUCCESS, bruger: bruger}))
        .catch(() => {
          const errors = [`Kunne ikke give supportadgang til institution: ${instRef}`];
          dispatch({type: constants.action.SUPPORTADGANG_FEJL, alerts: {fejl: errors, info: []}})
        });
  };
}