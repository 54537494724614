import React, {Component} from 'react';
import Content from "./Content";
import Footer from "./util/Footer";
import HeaderConnector from "./util/HeaderConnector";

class App extends Component {

  render() {
    return (
      <div className="stil-site clearfix">
        <HeaderConnector />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default App;
