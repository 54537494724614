import React, {Component} from "react";
import AfidentificerModal from "../modals/AfidentificerModal";

class AfidentificerComponent extends Component {
    state = { show: false };

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    render() {
        const {soegebrugere, afidentificerPerson} = this.props;

        return <div className={"afidentificer-button"}>
            <button className={"hand button stil-primary-button"}
                    onClick={this.showModal}>
                Afidentificer bruger
            </button>
            <AfidentificerModal
                visModal={this.state.show}
                lukModal={this.hideModal}
                soegebrugere={soegebrugere}
                afidentificerPerson={afidentificerPerson}
            />
        </div>;
    }
}

export default AfidentificerComponent;