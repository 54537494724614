import {connect} from 'react-redux'
import * as actions from "../actions/soegning";
import * as loginActions from "../actions/login";
import InstitutionSoegningMain from "./InstitutionSoegningMain";

const mapStateToProps = (state) => {
  return state.soegning;
};

const mapDispatchToProps  = (dispatch) => {
  return {
    soegOrganisationer: (instnr) => dispatch(actions.soegOrganisationer(instnr)),
    supportadgang: (instRef) => dispatch(loginActions.supportadgang(instRef)),
    hentKildestatistik: (instRef) => dispatch(actions.hentKildestatistik(instRef)),
    skjulKildestatistikModal: () => dispatch(actions.skjulKildestatistikModal())
  }
};

const InstitutionSoegningConnector = connect(mapStateToProps, mapDispatchToProps)(InstitutionSoegningMain);
export default InstitutionSoegningConnector;
