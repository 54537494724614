import {fetchWithoutResponseBody} from '../util/fetch';
import constants from "../util/constants";


export function afidentificerPerson(personUuid) {
  return dispatch => {
    var url = `${constants.api.afidentificerUrl}`.replace("{personUuid}", personUuid);

    dispatch(opdatererAfidentificering());
    fetchWithoutResponseBody(url, 'DELETE')
        .then(() => {
          dispatch(afidentificerPersonSucces(["Succesfuld afidentificering"]))
        })
        .catch((errorResponse) => {
          dispatch(afidentificerPersonFejl(["Afidentificering fejlede"]));
        });
  };
}

function opdatererAfidentificering() {
  return {
    type: constants.action.AFIDENTIFICERER_PERSON
  }
}

function afidentificerPersonSucces() {
  return {
    type: constants.action.AFIDENTIFICERING_SUCCES,
    alerts: {fejl: []},
  }
}

function afidentificerPersonFejl(errors) {
  return {
    type: constants.action.AFIDENTIFICERING_FEJL,
    alerts: {fejl: errors},
  }
}
