import React, {Component} from 'react'
import constants from '../util/constants.js';
import SoegeForm from "../pages/SoegeForm";
import AlertBar from "../util/AlertBar";
import {Navigate} from "react-router-dom";
import SortingChevron from "../util/SortingChevron";
import SoegeBrugerSupport from "../pages/SoegeBrugerSupport";
import Pagination from "../pages/Pagination";
import ElevModal from "../modals/ElevModal";
import MedarbejderModal from "../modals/MedarbejderModal";
import EksternModal from "../modals/EksternModal";
import AfidentificerComponent from "../pages/AfidentificerComponent";
import HistorikComponent from "../pages/HistorikComponent";

class BrugerSupportSoegningMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeSort: "",
            sortDirection: ""
        };
    }

    componentDidMount() {
        let elmnt = document.getElementById("brugere-header");
        if (elmnt) {
            elmnt.scrollTop = 200;
        }
    }

    componentDidUpdate() {
        let elmnt = document.getElementById("brugere-header");
        if (elmnt) {
            elmnt.scrollIntoView(true);
        }
    }

    sort(soegebrugere, field) {

        const {brugerSoegningSupport, soegning} = this.props;
        if (this.state.activeSort === field && this.state.sortDirection === 'asc') {
            this.setState({
                activeSort: field,
                sortDirection: "desc",
            });
            brugerSoegningSupport(soegning.soegestreng, field, "desc", 0)
        } else {
            this.setState({
                activeSort: field,
                sortDirection: "asc",
            });
            brugerSoegningSupport(soegning.soegestreng, field, "asc", 0)
        }
    }

    render() {
        const {
            supportadgang,
            brugerSoegningSupport,
            hentElev,
            hentMedarbejder,
            hentEkstern,
            skjulAktoerModal,
            opretBrugeradministrator,
            sletBrugeradministrator,
            soegning,
            login,
            afidentificerPerson,
        } = this.props;

        let placeholder = "Søg på CPR eller UniID";
        let soegEfterBrugere = "Søg efter bruger";

        if (soegning.status === constants.status.supportadgangSuccess)
            return <Navigate to="/"/>;

        if (soegning.status === constants.status.afidentificerPersonSuccess) {
            return (
                <div>
                    <div className="margtop30">
                    <section>
                        <div id="alert-success" className="alert success">
                            <p className="alertBody">
                                <table className="noborder">
                                    <tbody>
                                    <tr className="noborder">
                                        <td className="noborder">Bruger med uniId {soegning.soegebrugere[0].uniId} er nu afidentificeret</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </section>
                    </div>
                    <section className="margbottom10 margtop30">
                        <SoegeForm id="brugere-search" soeg={brugerSoegningSupport}
                                   soeger={soegning.status === constants.status.brugerSupportSoeger}
                                   titel={soegEfterBrugere}
                                   placeholder={placeholder}/>
                        <div>
                            <h2>Historik</h2>
                            <p>Fremsøg en bruger for at vise historikken.</p>
                        </div>
                    </section>
                </div>
            );
        }

        if (soegning.soegebrugere.length === 0 && soegning.historikListe.length === 0) {
            return (
                    <div className="margtop30">
                        <section>
                            <AlertBar alerts={soegning.alerts}/>
                        </section>
                        <section className="margbottom10">
                            <SoegeForm id="brugere-search" soeg={brugerSoegningSupport}
                                       soeger={soegning.status === constants.status.brugerSupportSoeger}
                                       titel={soegEfterBrugere}
                                       placeholder={placeholder}/>
                            <div className="margbottom50">
                                <h2>Historik</h2>
                                <p>Fremsøg en bruger for at vise historikken.</p>
                            </div>
                        </section>
                    </div>
            );
        }

        if (soegning.soegebrugere.length === 0 && soegning.historikListe.length > 0) {
            return (
                    <div className="stil-full-width">
                        <div className="margtop30">
                            <section className="margbottom10">
                                <SoegeForm id="brugere-search" soeg={brugerSoegningSupport}
                                       soeger={soegning.status === constants.status.brugerSupportSoeger}
                                       titel={soegEfterBrugere}
                                       placeholder={placeholder}/>
                                <div className="margbottom50">
                                <p className={"fremhaevet-tekst"}>Ingen aktiv bruger fundet</p>
                                <hr className="solid"/>
                                <HistorikComponent
                                    historikListe={soegning.historikListe} erSupport={login.bruger.erSupport}
                                />
                                </div>
                            </section>
                        </div>
                    </div>
            );
        }

        return (
            <div>
                <div className="stil-full-width">
                    <section>
                        <AlertBar alerts={soegning.alerts}/>
                    </section>
                    <div className="margtop30"/>
                    <section>
                        <SoegeForm id="bruger-search" soeg={brugerSoegningSupport}
                                   soeger={soegning.status === constants.status.brugerSupportSoeger}
                                   titel={soegEfterBrugere}
                                   placeholder={placeholder}/>
                    </section>
                    <div className="margbottom10"/>
                </div>
                <div>
                    <section>
                        <div className="stil-col">
                            <h2 id="brugere-header" className="margtop30">Aktører</h2>

                            <table className="test-table stil-folding-table margtop10" id={"aktoer-table"}>
                                <thead>
                                <tr>
                                    <th id="header-fornavn">
                                        Navn&nbsp;
                                    </th>

                                    <th id="header-uni-id">
                                        UNI-ID&nbsp;
                                    </th>

                                    <th id="header-institution" className="hand"
                                        onClick={() => this.sort(soegning.soegebrugere, "institutionRef")}
                                        title="Sortér på institution">
                                        Institution&nbsp;
                                        <SortingChevron active={this.state.activeSort === "institutionRef"}
                                                        direction={this.state.sortDirection}/>
                                    </th>

                                    <th id="header-klasse" className="hand"
                                        onClick={() => this.sort(soegning.soegebrugere, "klasse")}
                                        title="Sortér på klasse">
                                        Klasse&nbsp;
                                        <SortingChevron active={this.state.activeSort === "klasse"}
                                                        direction={this.state.sortDirection}/>
                                    </th>

                                    <th id="header-type" className="hand"
                                        onClick={() => this.sort(soegning.soegebrugere, "type")}
                                        title="Sortér på type">
                                        Type&nbsp;
                                        <SortingChevron active={this.state.activeSort === "type"}
                                                        direction={this.state.sortDirection}/>
                                    </th>

                                    <th id="header-sidstaendret" className="hand"
                                        onClick={() => this.sort(soegning.soegebrugere, "sidstAendret")}
                                        title="Sortér på sidst ændret">
                                        Sidst ændret&nbsp;
                                        <SortingChevron active={this.state.activeSort === "sidstAendret"}
                                                        direction={this.state.sortDirection}/>
                                    </th>

                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    soegning.soegebrugere == null ? null :
                                        soegning.soegebrugere.map(soegebruger => {
                                            return <SoegeBrugerSupport key={soegebruger.aktoerId}
                                                                       soegebruger={soegebruger}
                                                                       hentElev={hentElev}
                                                                       hentMedarbejder={hentMedarbejder}
                                                                       hentEkstern={hentEkstern}
                                                                       supportadgang={supportadgang}
                                            />
                                        })
                                }

                                </tbody>
                            </table>
                            <Pagination side={soegning.side} sidsteSide={soegning.sidsteside}
                                        soeg={brugerSoegningSupport} soegestreng={soegning.soegestreng}
                                        sortfelt={soegning.sortfelt} sortdirection={soegning.sortdirection}/>
                            <AfidentificerComponent
                                soegebrugere={soegning.soegebrugere}
                                afidentificerPerson={afidentificerPerson}
                                brugerSoegningSupport={brugerSoegningSupport}
                            />
                            <hr className="solid"/>
                            <HistorikComponent
                                historikListe={soegning.historikListe} erSupport={login.bruger.erSupport}
                            />

                        </div>

                        <ElevModal elev={soegning.elev}
                                   visModal={soegning.elev !== null}
                                   erSupport={login.bruger.erSupport}
                                   lukModal={skjulAktoerModal}/>

                        <MedarbejderModal medarbejder={soegning.medarbejder}
                                          visModal={soegning.medarbejder !== null}
                                          erSupport={login.bruger.erSupport}
                                          lukModal={skjulAktoerModal}
                                          opretBrugeradministrator={opretBrugeradministrator}
                                          sletBrugeradministrator={sletBrugeradministrator}
                                          aktoerErBrugeradm={soegning.aktoerErBrugeradm}
                                          detaljesideAlerts={soegning.detaljesideAlerts}
                                          detaljesideSuccess={soegning.detaljesideSuccess}
                        />

                        <EksternModal ekstern={soegning.ekstern}
                                      visModal={soegning.ekstern !== null}
                                      erSupport={login.bruger.erSupport}
                                      lukModal={skjulAktoerModal}
                                      opretBrugeradministrator={opretBrugeradministrator}
                                      sletBrugeradministrator={sletBrugeradministrator}
                                      aktoerErBrugeradm={soegning.aktoerErBrugeradm}
                                      detaljesideAlerts={soegning.detaljesideAlerts}
                                      detaljesideSuccess={soegning.detaljesideSuccess}
                        />
                    </section>
                </div>
            </div>
        );
    }
}

export default BrugerSupportSoegningMain;

