import './css/main.css';
import './css/stil-baseline-2018.css';
import './css/stil-font-ibmplex.css';
import 'babel-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import configureStore from "./store/configureStore";
import constants from './util/constants';
import AlertBar from "./util/AlertBar";

const state = {
  myInitState: {
    login: constants.loginStateInit,
    forside: {
      previousType: "",
      status: false,
      alerts: {fejl: [], info: []},
      showOrganisationModal: false,
      basicLoginFejl: null
    },
    soegning: {
      alerts: {fejl: [], info: []},
      detaljesideAlerts: {fejl: []},
      detaljesideSuccess: {success: []},
      status: false,
      soegestreng: "",
      sortfelt: "",
      sortdirection: "asc",
      side: 0,
      sidsteside: 1,
      soegebrugere: [],
      historikListe: [],
      elev: null,
      medarbejder: null,
      ekstern: null,
      kildestatistik: null
    }
  },
};

state.myInitState.forside.alerts = AlertBar.getAlertsFromQuery();

const { store, persistor } = configureStore(state.myInitState);

const container = document.getElementById('react');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename="/brugeroversigt/">
        <App/>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
