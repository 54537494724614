import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import ForsideConnector from "./containers/ForsideConnector";
import LoginConnector from "./containers/LoginConnector";
import VersionConnector from "./containers/VersionConnector";
import constants from "./util/constants";
import SoegeConnector from "./containers/SoegeConnector";
import InstitutionSoegningConnector from "./containers/InstitutionSoegningConnector";
import BrugerSupportSoegningConnector from "./containers/BrugerSupportSoegningConnector";

class Content extends Component {

  render() {

    // Login nulstiller token og viderestiller til BE-login. Dette er nødvendigt ved relogin,
    // da det nye token i query param ikke vil overskrive et token i session storage.
    // Logout viderestiller direkte til BE-logout, som vil viderestille tilbage til /?logout.
    // Hermed er det kun tilslutning, der behøver at kende til sin backend.

    return (
      <div className="stil-full-width">
        <Routes>
          <Route path="/" element={<ForsideConnector/>}/>
          <Route path={constants.navigation.urlLogin} element={<LoginConnector/>}/>
          <Route path={constants.navigation.urlVersion} element={<VersionConnector/>}/>
          <Route path={constants.navigation.urlSoegning} element={<SoegeConnector/>}/>
          <Route path={constants.navigation.urlInstitutionsSoegning} element={<InstitutionSoegningConnector/>}/>
          <Route path={constants.navigation.urlBrugerSoegning} element={<BrugerSupportSoegningConnector/>}/>
          <Route path={constants.navigation.urlLogout} element={<LoginConnector/>}/>
        </Routes>
      </div>
    )
  }
}

export default Content;
