import constants from "../util/constants";
import fetchJson from "../util/fetch";

export function loadFrontendVersion() {
  return loadVersion(constants.api.brugeradministrationFrontendUrl,
      constants.action.GET_FRONTEND_VERSION_SUCCESS,
      constants.action.GET_FRONTEND_VERSION_FEJL);
}

export function loadBackendVersion() {
  return loadVersion(constants.api.brugeradministrationBackendUrl,
      constants.action.GET_BACKEND_VERSION_SUCCESS,
      constants.action.GET_BACKEND_VERSION_FEJL);
}

function loadVersion(url, successAction, errorAction) {
  return (dispatch) => {
    fetchJson(url, 'GET')
        .then((json) => {
          dispatch({ type: successAction, version: json })
        }).catch(() => {
          dispatch({ type: errorAction })
        });
  };
}
