import React, {Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import queryString from 'query-string';
import {isEmptyStr} from './util';
import constants from "./constants";

class AlertBar extends Component {
  /**
   * Hjælpemetode, der returnerer alerts fra query-string til brug med AlertBar
   *
   * @returns {{fejl: *, info: *}}
   */
  static getAlertsFromQuery() {
    const emptyValue = {
      fejl: [],
      info: []
    };

    try {
      // Forhindr kode udefra i at angive beskeder.
      // Referrer kan overstyres i forhold til, hvor meget den afslører
      // (https://www.w3.org/TR/referrer-policy/#referrer-policies), men kan ikke spoofes uden
      // lokal indsats. Dvs. en angriber ikke vil kunne guide ofre ind på siden med os selv som
      // referrer.
      // IE understøtter ikke URL, derfor ikke "new URL(document.referrer).origin".
      // Men window.location er et objekt med URL's attributter.
      // Vær doven med det regulære udtryk. Hvis det ikke matcher, opnår vi stadig det ønskede.

      const refOrigin = document.referrer.replace(/^(https:\/\/.+?)\/.*/, '$1');
      if (!(refOrigin === window.location.origin || refOrigin === "https://nemlog-in.mitid.dk" || refOrigin === "https://test-nemlog-in.pp.mitid.dk")) {
        return emptyValue;
      }
    } catch (e) {
      // F.eks. ved tom streng (f.eks. ved request fra a-element med rel="noreferrer").
      return emptyValue;
    }

    const params = queryString.parse(window.location.search);
    const error = params['error'];
    const info = params['info'];
    const errorAlerts = isEmptyStr(error) ? [] : [error];
    const infoAlerts = isEmptyStr(info) ? [] : [info];
    return {
      fejl: errorAlerts,
      info: infoAlerts
    };
  }

  render() {
    const {
      alerts
    } = this.props;
    if (alerts === null || alerts === undefined) return null;
    const {
      fejl,
      info
    } = alerts;

    if (fejl !== null && fejl !== undefined && fejl.length > 0) {
      if (fejl[0] === "401") {
        fejl[0] = "Du har været inaktiv for længe. Log venligst på igen."
      }

      return React.createElement("div", { id: "alert-fejl", className: "alert error"},
          React.createElement("p", { className: "alertBody" },
              React.createElement("table", { className: "noborder"},
                  React.createElement("tbody", null,
                      fejl.map((tekst, index) => {
                        return React.createElement("tr", { key: index, className: "noborder" },
                            React.createElement("td", { className: "noborder" }, tekst));
                      })
                  )
              )
          )
      );
    }


    if (info !== null && info !== undefined && info.length > 0) {
      return React.createElement("div", {
        id: "alert-info",
        className: "alert info"
      }, React.createElement("p", {
        className: "alertBody"
      }, React.createElement("table", {
        className: "noborder"
      }, React.createElement("tbody", null, info.map((tekst, index) => {
        return React.createElement("tr", {
          key: index,
          className: "noborder"
        }, React.createElement("td", {
          className: "noborder"
        }, tekst));
      })))));
    }

    return null;
  }

}

export default AlertBar;