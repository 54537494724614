import constants from "../util/constants";

export function soegeReducer(state = [], action) {
  let stat;

  switch(action.type) {

    case constants.action.SOEGER:
      return { ...state, soegestreng: action.soegestreng, sortfelt: action.sortfelt,
                         sortdirection: action.sortdirection, side: action.side, elev: null,
                         medarbejder: null, ekstern: null, kontaktperson: null, kildestatistik: null, aktoerErBrugeradm: null,
                         alerts: {fejl: [], info: []},  detaljesideAlerts: {fejl: []}, detaljesideSuccess: {success: []}, status: constants.status.soeger };

    case constants.action.SOEG_FEJL:
      return { ...state, soegebrugere: [], alerts: action.alerts, status: constants.status.soegFejl };

    case constants.action.SOEG_SUCCES:
      stat = action.soegebrugere.length === 0 ? constants.status.soegSuccesIngenFundet : constants.status.soegSucces;
      return { ...state, alerts: action.alerts, soegebrugere: action.soegebrugere, side: action.side, sidsteside: action.sidsteside, status: stat };

    case constants.action.BRUGER_SOEGER_SUPPORT:
      return { ...state, soegestreng: action.soegestreng, sortfelt: action.sortfelt,
        sortdirection: action.sortdirection, side: action.side, soegebrugere: [], historikListe:[], elev: null,
        medarbejder: null, ekstern: null, kontaktperson: null, kildestatistik: null, aktoerErBrugeradm: null,
        alerts: {fejl: [], info: []},  detaljesideAlerts: {fejl: []}, detaljesideSuccess: {success: []}, status: constants.status.brugerSupportSoeger };

    case constants.action.BRUGER_SOEG_SUPPORT_FEJL:
      return { ...state, soegebrugere: [], historikListe:[], alerts: action.alerts, status: constants.status.brugerSupportSoegFejl };

    case constants.action.BRUGER_SOEG_SUPPORT_SUCCES:
      stat = action.soegebrugere.length === 0 ? constants.status.soegSuccesIngenFundet : constants.status.brugerSupportSoegSucces;
      return { ...state, alerts: action.alerts, soegebrugere: action.soegebrugere, historikListe: action.historikListe, side: action.side, sidsteside: action.sidsteside, status: stat };

    case constants.action.HENTER_ELEV:
      return { ...state, elev: null, medarbejder: null, ekstern: null, kontaktperson: null, aktoerErBrugeradm: null, status: constants.status.henterElev };

    case constants.action.HENT_ELEV_FEJL:
      return { ...state, alerts: action.alerts, status: constants.status.hentElevFejl };

    case constants.action.HENT_ELEV_SUCCES:
      return { ...state, elev: action.elev, status: constants.status.hentElevSucces };

    case constants.action.HENTER_MEDARBEJDER:
      return { ...state, elev: null, medarbejder: null, ekstern: null, kontaktperson: null, aktoerErBrugeradm: null, status: constants.status.henterMedarbejder };

    case constants.action.HENT_MEDARBEJDER_FEJL:
      return { ...state, alerts: action.alerts, status: constants.status.hentMedarbejderFejl };

    case constants.action.HENT_MEDARBEJDER_SUCCES:
      return { ...state, medarbejder: action.medarbejder, status: constants.status.hentMedarbejderSucces };

    case constants.action.HENTER_EKSTERN:
      return { ...state, elev: null, medarbejder: null, ekstern: null, kontaktperson: null, aktoerErBrugeradm: null, status: constants.status.henterEkstern };

    case constants.action.HENT_EKSTERN_FEJL:
      return { ...state, alerts: action.alerts, status: constants.status.hentEksternFejl };

    case constants.action.HENT_EKSTERN_SUCCES:
      return { ...state, ekstern: action.ekstern, status: constants.status.hentEksternSucces };

    case constants.action.HENTER_KILDESTATISTIK:
      return { ...state, elev: null, medarbejder: null, ekstern: null, kontaktperson: null, aktoerErBrugeradm: null, status: constants.status.henterKildestatistik };

    case constants.action.HENT_KILDESTATISTIK_FEJL:
      return { ...state, alerts: action.alerts, status: constants.status.hentKildestatistikFejl };

    case constants.action.HENT_KILDESTATISTIK_SUCCES:
      return { ...state, kildestatistik: action.kildestatistik, status: constants.status.hentKildestatistikSucces };

    case constants.action.SKJUL_KILDESTATISTIK_MODAL:
      return { ...state, elev: null, medarbejder: null, ekstern: null, kontaktperson: null, kildestatistik: null, status: constants.status.skjulKildestatistikModal };

    case constants.action.SKJUL_AKTOER_MODAL:
      return { ...state, elev: null, medarbejder: null, ekstern: null, kontaktperson: null, status: constants.status.skjulAktoerModal };

    case constants.action.AFIDENTIFICERER_PERSON:
      return { ...state, status: constants.status.afidentificerPerson };

    case constants.action.AFIDENTIFICERING_FEJL:
      return { ...state, alerts: action.alerts, status: constants.status.afidentificerPersonFejl };

    case constants.action.AFIDENTIFICERING_SUCCES:
      return { ...state, status: constants.status.afidentificerPersonSuccess };

    case constants.action.SOEGER_ORGANISATION:
      return { ...state, soegeOrganisationer: [], status: constants.status.soegerOrganisation };

    case constants.action.SOEG_ORGANISTION_SUCCESS:
      return { ...state, soegeOrganisationer: [action.orgnaisation], alerts: {fejl: [], info: []}, status: constants.status.soegOrganisationSuccess };

    case constants.action.SOEG_ORGANISTION_FEJL:
      return { ...state, soegeOrganisationer: [], alerts: action.alerts, status: constants.status.soegOrganisationFejl };

    case constants.action.SUPPORTADGANG_SUCCESS:
      return {...state, bruger: action.bruger, status: constants.status.supportadgangSuccess};

    case constants.action.OPRET_BRUGERADM_SUCCESS:
      return {...state, aktoerErBrugeradm: action.aktoerErBrugeradm, detaljesideSuccess: action.detaljesideSuccess, detaljesideAlerts: {fejl: []}, status: constants.status.opretBrugeradmSuccess};

    case constants.action.OPRET_BRUGERADM_FEJL:
      return { ...state, detaljesideAlerts: action.detaljesideAlerts, detaljesideSuccess: {success: []},  status: constants.status.opretBrugeradmFejl};

    case constants.action.SLET_BRUGERADM_SUCCESS:
      return {...state, aktoerErBrugeradm: action.aktoerErBrugeradm, detaljesideSuccess: action.detaljesideSuccess, detaljesideAlerts: {fejl: []}, status: constants.status.sletBrugeradmSuccess};

    case constants.action.SLET_BRUGERADM_FEJL:
      return { ...state, detaljesideAlerts: action.detaljesideAlerts, detaljesideSuccess: {success: []}, status: constants.status.sletBrugeradmFejl};

    case constants.action.OPDATERER_BRUGERADM:
      return { ...state, detaljesideAlerts: {fejl: []}, detaljesideSuccess: {success: []}, status: constants.status.opdatererBrugeradm};

    default:
      return {...state, previousType: action.type };
  }
}

