import React, {Component} from 'react'
import {formatTimestamp} from "../util/util";
import CprNummerComponent from './CprNummerComponent'

class HistorikComponent extends Component {

    render() {
        const {historikListe, erSupport} = this.props;

        function ikon(tilfoejelse) {
            let ikon;
            switch (tilfoejelse) {
                case true:
                    ikon = "#add"
                    break
                case false:
                    ikon = "#remove"
                    break
                default:
                    ikon = ""
                    break
            }
            return ikon;
        }

        if (historikListe.length === 0) {
            return (
                <>
                    <div className="margbottom50">
                        <h2>Historik</h2>
                        <p>Der findes ingen historik for brugeren.</p>
                    </div>
                </>
            );
        }


        return <div className="margbottom50">
            <h2>Historik</h2>
            <div className="hide-base-svg">
                <svg xmlns="http://www.w3.org/2000/svg">
                    <symbol id="add" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path fill="#358000" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></symbol>
                    <symbol id="remove" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path fill="#CC0000" d="M19 13H5v-2h14v2z"/></symbol>
                </svg>
            </div>
            <table className="test-table stil-folding-table margtop10" id={"historik-table"}>
                <thead>
                <tr>
                    <th id="header-tidspunkt">
                        Tidspunkt&nbsp;
                    </th>

                    <th id="header-inst">
                        Institution&nbsp;
                    </th>

                    <th id="header-uniid">
                        UniId&nbsp;
                    </th>

                    <th id="header-cprnr">
                        CPR nummer&nbsp;
                    </th>

                    <th id="header-haendelse">
                        Hændelse&nbsp;
                    </th>

                    <th id="header-aendretAf">
                        Ændret af&nbsp;
                    </th>

                </tr>
                </thead>
                <tbody>
                {
                    historikListe == null ? null :
                        historikListe.map(historik =>
                        <tr>
                            <td>{formatTimestamp(historik.tidspunkt)}</td>
                            <td>{historik.institutionRef} {historik.institutionNavn}</td>
                            <td>{historik.uniId}</td>
                            <td><CprNummerComponent erSupport={erSupport} cprNr={historik.cprNr} /></td>
                            <td>
                                <svg className="icon-svg" id={"icon-svg-historik"} focusable="false" aria-hidden="true">
                                    <use href={ikon(historik.erTilfoejelse)}></use>
                                </svg>{historik.haendelse}
                                {historik.haendelse === "Bruger afidentificeret" ?
                                    <img id="skull-outline" src="img/skull-outline.svg" alt={"dødningehoved"}/>
                                    : null
                                }
                            </td>
                            <td>{historik.aendretAf}</td>
                        </tr>)
                }
                </tbody>
            </table>
        </div>;
    }
}
export default HistorikComponent;