import React, {Component} from "react";
import constants from "./constants";
import {Link} from "react-router-dom";
import AlertBar from "./AlertBar";
import OrganisationModal from "../modals/OrganisationModal";

class Header extends Component {

  render() {
    const {login, forside, visOrganisationModal, skjulOrganisationModal, hentOrganisationer, skiftOrganisation} = this.props;
    const alerts = AlertBar.getAlertsFromQuery();

    return (
      <div className="stil-full-width">
        <header style={{display: "flex", justifyContent: "space-between"}}>
          <a href={constants.navigation.basename}>
            <img className="stil-logo" src="img/UVM_web_STIL163.png" alt="Logo for Styrelsen for it og læring"/>
          </a>
          {
            (login.loggedIn && login.bruger) || alerts.fejl.length > 0 ?
                <div>
                  {
                    login.bruger.flereOrganisationer ?
                        <span>
                          {login.bruger.navn}
                          {
                            login.bruger.organisation ?
                                <span>
                                  {" - "}
                                  <button className="hand" id="handButton" onClick={visOrganisationModal}>{login.bruger.organisation.navn} ({login.bruger.organisation.kode})</button>
                                </span> :
                                null
                          }
                        </span> :
                        <span>{login.bruger.navn} {login.bruger.organisation ? `- ${login.bruger.organisation.navn} (${login.bruger.organisation.kode})` : ""}</span>
                  }

                  <Link className="margleft10" to={constants.navigation.urlLogout}>
                    <button className="button button-primary login-button" title="Log ud af Brugeradministrationen">Log ud</button>
                  </Link>
                </div> :
                <Link to={constants.navigation.urlLogin}>
                  <button className="button button-primary login-button" title="Log ind i Brugeradministrationen">Log ind</button>
                </Link>
          }
        </header>
        <div className="stil-full-width"/>
        <header>
          <em className="stil-tjeneste-navn">Brugeroversigt</em>
        </header>

        <OrganisationModal status={login.status}
                           bruger={login.bruger}
                           visModal={forside.showOrganisationModal}
                           lukModal={skjulOrganisationModal}
                           hentOrganisationer={hentOrganisationer}
                           organisationer={login.organisationer}
                           skiftOrganisation={skiftOrganisation}/>
      </div>
    );
  }
}

export default Header;
