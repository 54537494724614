import {connect} from 'react-redux'
import * as actions from "../actions/soegning";
import * as loginActions from "../actions/login";
import BrugerSupportSoegningMain from "./BrugerSupportSoegningMain";
import * as brugeradmActions from "../actions/brugeradministrator";
import * as afidentificerActions from "../actions/afidentificer";

const mapStateToProps = (state) => {
    return {
        soegning: state.soegning,
        login: state.login
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        supportadgang: (instRef) => dispatch(loginActions.supportadgang(instRef)),
        brugerSoegningSupport: (soegestreng, sortfelt, sortdirection, side) => dispatch(actions.brugerSoegningSupport(soegestreng, sortfelt, sortdirection, side)),
        hentElev: (aktoerUuid) => dispatch(actions.hentElev(aktoerUuid)),
        opretBrugeradministrator: (aktoerUuid) => dispatch(brugeradmActions.opretBrugeradministrator(aktoerUuid)),
        sletBrugeradministrator: (aktoerUuid) => dispatch(brugeradmActions.sletBrugeradministrator(aktoerUuid)),
        afidentificerPerson: (personUuid) => dispatch(afidentificerActions.afidentificerPerson(personUuid)),
        hentMedarbejder: (aktoerUuid) => dispatch(actions.hentMedarbejder(aktoerUuid)),
        hentEkstern: (aktoerUuid) => dispatch(actions.hentEkstern(aktoerUuid)),
        skjulAktoerModal: () => dispatch(actions.skjulAktoerModal())
    }
};

const BrugerSupportSoegningConnector = connect(mapStateToProps, mapDispatchToProps)(BrugerSupportSoegningMain);
export default BrugerSupportSoegningConnector;
