import constants from "../util/constants";

export function forsideReducer(state = [], action) {

  switch(action.type) {

    case constants.NULSTIL_ALERTS:
      return {
        ...state,
        previousType: action.type,
        status: constants.status.nulstiller_alerts,
        alerts: {fejl: [], info: []}
      };

    case constants.action.VIS_ORGANISATION_MODAL:
      return {
        ...state,
        previousType: action.type,
        showOrganisationModal: true
      };

    case constants.action.SKIFT_ORGANISATION_SUCCESS:
    case constants.action.SKJUL_ORGANISATION_MODAL:
      return {
        ...state,
        previousType: action.type,
        showOrganisationModal: false
      };

    default:
      return {...state, previousType: action.type };
  }
}

