import React, {Component} from 'react'
import AktoerView from "./AktoerView";
import Check from "../util/Check";
import BeskyttetDataComponent from "./BeskyttetDataComponent";

class Kontaktperson extends Component {

  render() {
    const {kontaktperson, index, erSupport} = this.props;
    const kontaktpersonNavn = kontaktperson.fornavn + " " + kontaktperson.efternavn;

    return (
      <div>
        { index === 0 ? null : <p>&nbsp;</p> }
        <h4>Kontaktperson: <BeskyttetDataComponent erSupport={erSupport} data={kontaktpersonNavn} erBeskyttet={kontaktperson.beskyttet} erKontaktperson={true}/></h4>
        <AktoerView aktoer={kontaktperson} erSupport={erSupport}/>
        <div className="line"><div className="label">Rolle</div><div className="field">{kontaktperson.rolle}</div></div>
        <div className="line"><div className="label">Myndighed</div><div className="field"><Check boo={kontaktperson.myndighed}/></div></div>
        <div className="line"><div className="label">Adgangsniveau</div><div className="field">{kontaktperson.adgangsniveau}</div></div>
      </div>
    );
  }
}

export default Kontaktperson;