module.exports = {

  tokenExpiresHeader: 'Auth-Token-Expires',
  defaultFetchOptions: {
    credentials: 'same-origin',
    headers: {'Accept': 'application/json'}
  },

  loginStateInit: {
    loggedIn: false,
    bruger: {
      brugerid: null,
      navn: null,
      cvr: null,
      organisation: null,
      flereOrganisationer: false,
      adgang: [],
      readonly: false,
      erSupport: false,
    },
    organisationer: [],
    alerts: {fejl: [], info: []}
  },

  konfiguration: {
    localStorage: {
      ORGANISATION: 'ORGANISATION',
      BRUGER: 'BRUGER',
    },
    organsiationModal: {
      organisationerPrSide: 10
    },
    stilCvr: "13223459"
  },

  navigation: {
    basename: '/brugeroversigt/',
    urlBruger: '/brugeroversigt/api/brugeroplysninger',
    urlBELogin: '/brugeroversigt/oidc/login',
    urlBELogout: '/brugeroversigt/logout',
    urlLogout: '/logoutFE',
    urlLogin: '/login',
    urlVersion: '/version',
    urlSoegning: '/soegning',
    urlInstitutionsSoegning: '/institutions-soegning',
    urlBrugerSoegning: '/bruger-soegning'
  },

  queryParams: {
    postLogoutParam: 'logout',
    selectOrganisationParam: 'select-organisation',
  },

  action: {
    NULSTIL_ALERTS: 'NULSTIL_ALERTS',
    LOGIN_CLEAR: "LOGIN_CLEAR",
    SELECT_ORGANISATION: "SELECT_ORGANISATION",
    VIS_ORGANISATION_MODAL: "VIS_ORGANISATION_MODAL",
    SKJUL_ORGANISATION_MODAL: "SKJUL_ORGANISATION_MODAL",
    SET_SELECT_ORGANISATION_FEJL: "SET_SELECT_ORGANISATION_FEJL",
    HENTER_ORGANISATIONER: "HENTER_ORGANISATIONER",
    HENT_ORGANISATIONER_SUCCESS: "HENT_ORGANISATIONER_SUCCESS",
    HENT_ORGANISATIONER_FEJL: "HENT_ORGANISATIONER_FEJL",
    SKIFTER_ORGANISATION: "SKIFTER_ORGANISATION",
    SKIFT_ORGANISATION_SUCCESS: "SKIFT_ORGANISATION_SUCCESS",
    SKIFT_ORGANISATION_FEJL: "SKIFT_ORGANISATION_FEJL",
    VIS_LOGIN_MODAL: "VIS_LOGIN_MODAL",
    SKJUL_LOGIN_MODAL: "SKJUL_LOGIN_MODAL",
    BASIC_LOGIN_SUCCESS: "BASIC_LOGIN_SUCCESS",
    BASIC_LOGIN_FEJL: "BASIC_LOGIN_FEJL",
    BEKRAEFT_NOTIFIKATIONSEMAIL_SUCCESS: "BEKRAEFT_NOTIFIKATIONSEMAIL_SUCCESS",
    BEKRAEFT_NOTIFIKATIONSEMAIL_FEJL: "BEKRAEFT_NOTIFIKATIONSEMAIL_FEJL",
    FORNY_KONTAKTPERSON_SUCCESS: "FORNY_KONTAKTPERSON_SUCCESS",
    FORNY_KONTAKTPERSON_FEJL: "FORNY_KONTAKTPERSON_FEJL",
    GET_FRONTEND_VERSION_SUCCESS: "GET_FRONTEND_VERSION_SUCCESS",
    GET_FRONTEND_VERSION_FEJL: "GET_FRONTEND_VERSION_FEJL",
    GET_BACKEND_VERSION_SUCCESS: "GET_BACKEND_VERSION_SUCCESS",
    GET_BACKEND_VERSION_FEJL: "GET_BACKEND_VERSION_FEJL",
    SOEGER: "SOEGER",
    SOEG_FEJL: "SOEG_FEJL",
    SOEG_SUCCES: "SOEG_SUCCES",
    BRUGER_SOEGER_SUPPORT: "BRUGER_SOEGER_SUPPORT",
    BRUGER_SOEG_SUPPORT_FEJL: "BRUGER_SOEG_SUPPORT_FEJL",
    BRUGER_SOEG_SUPPORT_SUCCES: "BRUGER_SOEG_SUPPORT_SUCCES",
    HENTER_ELEV: "HENTER_ELEV",
    HENT_ELEV_FEJL: "HENT_ELEV_FEJL",
    HENT_ELEV_SUCCES: "HENT_ELEV_SUCCES",
    HENTER_MEDARBEJDER: "HENTER_MEDARBEJDER",
    HENT_MEDARBEJDER_FEJL: "HENT_MEDARBEJDER_FEJL",
    HENT_MEDARBEJDER_SUCCES: "HENT_MEDARBEJDER_SUCCES",
    HENTER_EKSTERN: "HENTER_EKSTERN",
    HENT_EKSTERN_FEJL: "HENT_EKSTERN_FEJL",
    HENT_EKSTERN_SUCCES: "HENT_EKSTERN_SUCCES",
    HENTER_KILDESTATISTIK: "HENTER_KILDESTATISTIK",
    HENT_KILDESTATISTIK_FEJL: "HENT_KILDESTATISTIK_FEJL",
    HENT_KILDESTATISTIK_SUCCES: "HENT_KILDESTATISTIK_SUCCES",
    SKJUL_KILDESTATISTIK_MODAL: "SKJUL_KILDESTATISTIK_MODAL",
    SKJUL_AKTOER_MODAL: "SKJUL_AKTOER_MODAL",
    SOEGER_ORGANISATION: "SOEGER_ORGANISATION",
    SOEG_ORGANISTION_FEJL: "SOEG_ORGANISTION_FEJL",
    SOEG_ORGANISTION_SUCCESS: "SOEG_ORGANISTION_SUCCESS",
    OPRET_BRUGERADM_FEJL: "OPRET_BRUGERADM_FEJL",
    OPRET_BRUGERADM_SUCCESS: "OPRET_BRUGERADM_SUCCESS",
    SLET_BRUGERADM_FEJL: "SLET_BRUGERADM_FEJL",
    SLET_BRUGERADM_SUCCESS: "SLET_BRUGERADM_SUCCESS",
    OPDATERER_BRUGERADM: "OPDATERER_BRUGERADM",
    AFIDENTIFICERER_PERSON: "AFIDENTIFICERE_PERSON",
    AFIDENTIFICERING_SUCCES: "AFIDENTIFICERING_SUCCES",
    AFIDENTIFICERING_FEJL: "AFIDENTIFICERING_FEJL",
    GIVER_SUPPORTADGANG: "GIVER_SUPPORTADGANG",
    SUPPORTADGANG_SUCCESS: "SUPPORTADGANG_SUCCESS",
    SUPPORTADGANG_FEJL: "SUPPORTADGANG_FEJL",
  },

  status: {
    login: 'Logger ind',
    login_fejl: 'Fejl i login',
    nulstiller_alerts: 'Nulstiller alerts',
    soeger: 'Søger efter brugere',
    soegSucces: 'Brugerne er fundet',
    soegSuccesIngenFundet: 'Der er ikke fundet nogen brugere',
    soegFejl: 'Søgningen er fejlet',
    brugerSupportSoeger: 'Support: Søger efter brugere',
    brugerSupportSoegSucces: 'Support: Brugerne er fundet',
    brugerSupportSoegFejl: 'Support: Søgningen er fejlet',
    henterElev: 'Henter elev',
    hentElevSucces: 'Eleven er hentet',
    hentElevFejl: 'Hent elev er fejlet',
    henterMedarbejder: 'Henter medarbejder',
    hentMedarbejderSucces: 'Medarbejderen er hentet',
    hentMedarbejderFejl: 'Hent medarbejder er fejlet',
    henterEkstern: 'Henter ekstern',
    hentEksternSucces: 'Ekstern er hentet',
    hentEksternFejl: 'Hent ekstern er fejlet',
    henterKildestatistik: 'Henter kildestatistik',
    hentKildestatistikSucces: 'Kildestatistik er hentet',
    hentKildestatistikFejl: 'Hent kildestatistik er fejlet',
    skjulKildestatistikModal: 'Skjuler kildestatistik modal',
    skjulAktoerModal: 'Skjuler aktør modal',
    henterOrganisationer: 'Henter organisationer',
    hentOrganisationerSuccess: 'Organisationer er hentet',
    hentOrganisationerFejl: 'Hent organisationer er fejlet',
    skifterOrganisation: 'Skifter organisation',
    skiftOrganisationSuccess: 'Skiftet til ny organisation',
    skiftOrganisationFejl: 'Skift til ny organisation fejlet',
    opdatererBrugeradm: 'Opdaterer brugeradministratorrettigheder',
    opretBrugeradmSuccess: 'Brugeradministrator oprettet',
    sletBrugeradmSuccess: 'Brugeradministrator slettet',
    opretBrugeradmFejl: 'Brugeradministrator ikke oprettet',
    sletBrugeradmFejl: 'Brugeradministrator ikke slettet',
    afidentificerPersonFejl: 'Afidentificering ikke gennemført',
    afidentificerPersonSuccess: 'Afidentificering gennemført',
    afidentificerPerson: 'Afidentificering i gang',
    soegerOrganisation: 'Søger efter organisation',
    soegOrganisationSuccess: 'Organisation er fundet',
    soegOrganisationFejl: 'Fejl ved organisationsøgning',
    giverSupportadgang: 'Giver supportadgang til institution',
    supportadgangSuccess: 'Supportadgang til institution',
    supportadgangFejl: 'Fejl ved supportadgang til institution',
  },

  api: {
    brugeradministrationFrontendUrl: '/brugeroversigt/version.json',
    brugeradministrationBackendUrl: '/brugeroversigt/version',
    soegning: '/brugeroversigt/api/institutioner/{instnr}/brugere',
    hentElevUrl: '/brugeroversigt/api/elever/{aktoerUuid}',
    hentMedarbejderUrl: '/brugeroversigt/api/medarbejdere/{aktoerUuid}',
    hentEksternUrl: '/brugeroversigt/api/eksterne/{aktoerUuid}',
    hentKontaktpersonUrl: '/brugeroversigt/api/kontaktpersoner/{aktoerUuid}',
    hentOrganisationerUrl: '/brugeroversigt/api/organisationer',
    hentKildestatistikUrl: '/brugeroversigt/api/organisationer/kildestatistik/{instRef}',
    skiftOrganisationUrl: '/brugeroversigt/api/skift-organisation',
    soegOrganisationUrl: '/brugeroversigt/api/organisation',
    supportadgangUrl: '/brugeroversigt/api/supportadgang/institution',
    brugeradministratorUrl: '/brugeroversigt/api/brugeradministrator/{aktoerUuid}',
    brugerSoegningSupport: '/brugeroversigt/api/support/brugere',
    afidentificerUrl: '/brugeroversigt/api/afidentificer/{personUuid}',
  }
}
