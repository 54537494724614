import {connect} from 'react-redux'
import Header from "./Header";
import * as loginActions from "../actions/login";

const mapStateToProps = (state) => {
  return {
    forside: state.forside,
    login: state.login
  };
};

const mapDispatchToProps  = (dispatch) => {
  return {
    visOrganisationModal: () => dispatch(loginActions.visOrganisationModal()),
    skjulOrganisationModal: () => dispatch(loginActions.skjulOrganisationModal()),
    hentOrganisationer: () => dispatch(loginActions.hentOrganisationer()),
    skiftOrganisation: (organisation) => dispatch(loginActions.skiftOrganisation(organisation))
  };
};

const HeaderConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
) (Header);

export default HeaderConnector;
